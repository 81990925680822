.App {
  text-align: center;
  background-color: #F6F39D;
  margin-right: 0;
}

hr {
  background-color: #F88080;
  height: 5px;
}
h1,h2,h3,h4,h5,h6 {
  font-family: 'Poppins', sans-serif;
}
h1 {
  font-size: 7.5rem;
  font-weight: 3rem;
  color: #384D65;
  line-height:1.15em;
  margin: 0;
}
h2 {
  border-bottom: 1px solid #F88080;
  padding-bottom: 10px;
  border-bottom-width: 5px;
  font-size: 2.5rem;
  font-weight: 4rem;
  color: #384D65;
  line-height:115%;
}
h3 {
  font-size: 225%;
  font-weight: 350%;
  color: #F88080;
}
h4 {
  font-size: 140%;
  font-weight: 300%;
  color: #F88080;
  line-height:0%;
}
h5 {
  font-size: 500%;
  font-weight: 300%;
  margin: 0;
  color: #384D65;
  line-height:115%;
}
h6 {
  font-size: 300%;
  font-weight: 100%;
  margin: 0;
  color: #384D65;
}
h7 {
  font-size: 1.4rem;
  font-weight: 1rem;
  margin: 0;
  color: #384D65;
  line-height:115%;
}
h8 {
  font-size: 1.2em;
  font-weight: 100%;
  margin: 0;
  color: #384D65;
  line-height:115%;
}
img {
  Object-fit: cover;
}

