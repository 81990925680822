.Footer {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    text-align: left;
    text-decoration: none;
    background-color: #54677D;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding-right: 2em;
    padding-left: 2em;
    
  }
  .FooterItem{
    font-size: 1.4em;
    font-family: 'Poppins', sans-serif;
    cursor:default;
    font-weight: bold;
  }