.Profile {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.imgContainer {
    position: relative;
    width: 500px;
    height: 600px;
    top: 0;
    left: 0;
  }
    
.profileBackground {
    width: 400px;
    height: 500px;
    position: relative;
    top: 0;
    left: 0;
    border: 1px;
    background: #F88080;;
}
.imgProfile {
    width: 400px;
    height: 500px;
    position: absolute;
    top: 30px;
    left: 30px;
  }