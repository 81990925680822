.About {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 10em;
    padding-bottom: 5em;
    padding-right: 5em;
    padding-left: 5em;
    text-align: left;
  }
.Content {
  flex-wrap:wrap;
}
@media (max-width: 1300px) {
  .About {
    text-align: center;
    flex-wrap:wrap;
    padding-bottom: 5em;
    padding-top: 8.5em;
  }
}