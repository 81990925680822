.Title {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 15em;
    padding-bottom: 0em;
    margin-bottom: 5em;
    padding-left: 5em;
    padding-right: 7em;
    text-align:left;
    /* flex-wrap:wrap-reverse; */
  }
.Emoji {
  transform: scale(1.2, 1.2);
  margin-bottom:5rem;
  margin-top: 0rem;
}
@media (max-width: 1100px) {
  .Title {
    text-align: center;
    flex-wrap:wrap-reverse;
  }
}