.Experience {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding-top: 5em;
    padding-bottom: 5em;
    text-decoration: none;
    flex-direction: column;
    text-align:left;
}
#content {
  display: flex;
  flex-direction: column;
}
.chrono {
  margin-bottom:5%;
  margin-left:5%;
  margin-right:5%;
  pointer-events:none;
}
.title {
  margin-left: 10%;
}
.rightContent {
  margin-right: 5%;
}
@media (max-width: 1000px) {
  .Experience {
    flex-wrap:wrap;
    justify-content: center;
    text-align:center;
  }
  .rightContent {
    margin-right: 5%;
    margin-left: 5%;
  }
  .title {
    margin-left: 10%;
    margin-right: 10%;
  }
}
